<template>
  <footer class="footer" id="footer">
    <div class="container">
      <p>&copy; 2024 Your Company Name. All rights reserved.</p>
      <p>
        Follow us on
        <a href="https://twitter.com" target="_blank">Twitter</a>,
        <a href="https://facebook.com" target="_blank">Facebook</a>, and
        <a href="https://instagram.com" target="_blank">Instagram</a>.
      </p>
    </div>
  </footer>
</template>



<style scoped>
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.container {
  width: 80%;
  margin: 0 auto;
}

a {
  color: #4da6ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
