<template>
  <div class="guitar" id="guitar">
    <b-container>
      <b-row>
        <b-col>
          <h2 class="mb-4">Guitar Performances</h2>
          <p>Here's a collection of my guitar performances. Enjoy the music!</p>
        </b-col>
      </b-row>
      <b-row>
        <!-- Performance Cards -->
        <b-col sm="12" md="6" lg="4" v-for="performance in performances" :key="performance.id">
          <b-card
              :title="performance.title"
              :img-src="performance.img"
              img-alt="Performance image"
              img-top
              tag="article"
              style="max-width: 20rem; margin-bottom: 20px"
              class="mb-2"
          >
            <b-card-text>
              {{ performance.description }}
            </b-card-text>
            <b-embed type="iframe" :src="performance.videoUrl" aspect="16by9"></b-embed>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script setup>
import {ref} from "vue";

const performances = ref(
    [
      {
        id: 1,
        title: 'Performance 1',
        description: 'Description of Performance 1...',
        img: 'link-to-image-of-performance-1.jpg',
        videoUrl: 'link-to-video-of-performance-1' // https://www.youtube.com/watch?v=Ldxw_2MjGZM&feature=youtu.be
      },
      // Add more performances here
    ]
)
</script>

<style scoped>
.guitar {
  padding: 20px 0;
}
</style>
