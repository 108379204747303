<template>
  <div class="coding" id="coding">
    <b-container>
      <b-row>
        <b-col>
          <h2 class="mb-4">Coding Projects</h2>
          <p>Here are some of my coding projects. Click on any project to learn more.</p>
        </b-col>
      </b-row>
      <b-row>
        <!-- Project Cards -->
        <b-col sm="12" md="6" lg="4" v-for="project in projects" :key="project.id">
          <b-card
              :title="project.title"
              :img-src="project.img"
              img-alt="Project image"
              img-top
              tag="article"
              style="max-width: 20rem; margin-bottom: 20px"
              class="mb-2"
          >
            <b-card-text>
              {{ project.description }}
            </b-card-text>
            <b-button variant="primary" :href="project.url" target="_blank">Learn More</b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script setup>
import {ref} from "vue";

const projects =  ref([
  {
    id: 1,
    title: 'PolloFeed',
    description: 'PolloFeed is an interactive web application that offers a unique experience for chicken enthusiasts and animal lovers. Through the website, users can watch live feeds of chickens and engage with them by triggering feeding mechanisms. This project integrates live streaming technology with hardware controls to provide real-time interaction. PolloFeed aims to create a fun and educational platform for users to learn about chickens and remotely interact with them, fostering a connection between technology and animal care.',
    img: 'https://pollofeed.com/favicon.ico', // Replace with actual link to an image of PolloFeed
    url: 'https://www.pollofeed.com' // Replace with the actual URL if different
  },
  {
    id: 2,
    title: 'Virtual Poker Room',
    description: 'Virtual Poker Room is an engaging online poker game designed to recreate the thrilling experience of a real-life poker room. Players can join various virtual tables, participate in Texas Hold\'em tournaments, and play against friends or random opponents from around the world. The game features an intuitive interface, realistic graphics, and seamless gameplay, ensuring both beginners and seasoned players can enjoy the nuances of poker. With built-in chat functionality and a robust matchmaking system, Virtual Poker Room offers a social and competitive platform for poker enthusiasts.',
    img: 'https://pokerplay.space/favicon.ico', // Replace with actual link to an image of your poker game
    url: 'https://pokerplay.space' // Replace with the actual URL of your game
  },
  {
    id: 3,
    title: 'Roulette Royale',
    description: 'Roulette Royale brings the classic and exhilarating casino game of roulette right to your screen. This immersive online game simulates a real roulette experience with a focus on authenticity and user-friendly design. Players can place their bets on numbers, colors, or ranges, just like in a traditional roulette game. With realistic graphics and sound effects, the game creates a captivating casino atmosphere. Features include a detailed statistics board to track previous results and betting strategies, making it enjoyable for both novice and experienced gamblers.',
    img: 'https://spinnie.space/favicon.ico', // Replace with actual link to an image of your roulette game
    url: 'https://spinnie.space' // Replace with the actual URL of your game
  },
  {
    id: 4,
    title: 'Blackjack Blitz',
    description: 'Blackjack Blitz is an online card game that captures the essence of classic blackjack. Designed for both casual players and blackjack aficionados, this game provides a realistic and engaging gaming experience. Players can challenge the dealer in a head-to-head battle, striving to hit 21 without going over. The game features intuitive controls, a user-friendly interface, and crisp graphics that mimic a real casino setting. Additional functionalities such as basic strategy hints, customizable table settings, and a leaderboard system enhance the gameplay, making Blackjack Blitz a go-to choice for online card gaming.',
    img: 'https://bjack.space/favicon.ico', // Replace with actual link to an image of your blackjack game
    url: 'https://bjack.space' // Replace with the actual URL of your game
  },


  // Add more projects here
])
</script>

<style scoped>
.coding {
  padding: 20px 0;
}
</style>
