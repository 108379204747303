<template>
  <div class="about" id="about">
    <b-container>
      <b-row>
        <b-col>
          <h2>About Me</h2>
          <p>{{ aboutText }}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const text = `Hello! I'm [Your Name], a dedicated software engineer by day and an enthusiastic guitarist and bassist by night. My journey in the tech world began [X years] ago, fueled by a fascination with how technology can solve complex problems and improve our daily lives. I specialize in [Your Specialization - e.g., full-stack development, machine learning, etc.] and have worked on a diverse range of projects, from developing user-friendly web applications to optimizing back-end systems for performance and scalability.

Beyond the world of ones and zeros, music is my sanctuary. I have been playing the guitar and bass for [Y years], and these instruments are an extension of my creative and expressive self. I love the way melodies and rhythms intertwine, creating a language that speaks directly to the soul. My musical journey has been one of continuous learning and joy, whether I'm strumming chords to my favorite songs, experimenting with new riffs, or jamming with fellow musicians.

In both coding and music, I find a unique blend of discipline and creativity. I believe that the structured thinking I've honed as a software engineer enhances my approach to music, and vice versa, the artistic side of music inspires innovative solutions in my software projects. This interplay keeps me motivated and constantly exploring new horizons.

When I'm not coding or lost in the world of music, I enjoy [other hobbies or interests, e.g., hiking, reading, photography]. These activities help me maintain a balanced and fulfilling life.

I'm always open to collaborating on exciting software projects or discussing the intricate beauty of a well-composed piece of music. Feel free to reach out to me at [Your Email] or connect with me on [LinkedIn/GitHub/Social Media].`

const aboutText = ref(text);
</script>

<style scoped>
.about {
  padding: 20px 0;
}
</style>
